<template>
  <main>
    <section class="main_tit">
      <NuxtLink :to="localePath('story')">
        <div class="img_tit">
          <div class="sub">
            <h4 v-html="$t('HOME.001')" />
            <div class="link_clamp">
              <span>{{ $t('HOME.002') }}</span>
            </div>
          </div>
          <video
              ref="bgVideo"
              preload="auto"
              controlslist="nodownload"
              oncontextmenu="return false;"
              autoplay="1"
              loop="1"
              playsinline
              muted
              poster="/images/main/kv_poster.png"
          >
            <source
                src="/video/index/Home_KV_video.mp4"
                type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;"
            />
          </video>
        </div>
      </NuxtLink>
    </section>

    <section class="main_owners">
      <NuxtLink :to="localePath('owners-world')">
        <h4>{{ $t('HOME.003') }}</h4>
        <div class="link_clamp">
          <span>{{ $t('HOME.004') }}</span>
        </div>
        <div class="view_world">
          <img class="img_pc" src="@/assets/images/main/bg_ow_pc.png" />
          <img class="img_mo" src="@/assets/images/main/bg_ow_mo.png" />
        </div>
      </NuxtLink>
    </section>

    <section class="main_exp">
      <h4>{{ $t('HOME.005') }}</h4>
      <div class="exp_list">
        <ul dir="ltr">
          <li>
            <NuxtLink
                :to="localePath('/story/ep02/ep02_020201_01')"
            >
              <img src="@/assets/images/main/exp_01.png" />
              <span>{{ $t('HOME.006') }}</span>
            </NuxtLink>
          </li>
          <li>
            <NuxtLink :to="localePath('/story/ep03/ep03_020301_02')">
              <img src="@/assets/images/main/exp_02.png" />
              <span>{{ $t('HOME.007') }}</span>
            </NuxtLink>
          </li>
          <li>
            <NuxtLink :to="localePath('/story/ep05/ep05_020500_02')">
              <img src="@/assets/images/main/exp_03.png" />
              <span>{{ $t('HOME.008') }}</span>
            </NuxtLink>
          </li>
          <li>
            <NuxtLink :to="localePath('/story/ep07/ep07_020702_01')">
              <img src="@/assets/images/main/exp_04.png" />
              <span>{{ $t('HOME.009') }}</span>
            </NuxtLink>
          </li>
        </ul>
      </div>
    </section>

    <section class="main_webtoon">
      <NuxtLink :to="localePath('webtoon')">
        <h4>{{ $t('HOME.010') }}</h4>
        <div class="link_clamp">
          <span>{{ $t('HOME.011') }}</span>
        </div>
        <div class="view_webtoon">
          <img class="img_pc" src="@/assets/images/main/bg_wt_pc.png" />
          <img class="img_mo" src="@/assets/images/main/bg_wt_mo.png" />
        </div>
      </NuxtLink>
    </section>

    <section class="main_game">
      <NuxtLink :to="localePath('game')">
        <h4>{{ $t('HOME.012') }}</h4>
        <div class="link_clamp">
          <span>{{ $t('HOME.013') }}</span>
        </div>
        <div class="view_game">
          <img class="img_pc" src="@/assets/images/main/bg_rg_pc.png" />
          <img class="img_mo" src="@/assets/images/main/bg_rg_mo.png" />
        </div>
      </NuxtLink>
    </section>

    <section class="main_more">
      <a
          v-if="isShowShellRecharge"
          href="https://shellrecharge.com/en-gb/find-a-charge-point"
          target="_blank"
      >
        <div class="find_shell">
          <h4>{{ $t('HOME.014') }}</h4>
          <div class="link_clamp">
            <span>{{ $t('HOME.015') }}</span>
          </div>
        </div>
      </a>
    </section>
    <div class="btn_top">
      <button type="button" @click="scrollTop">TOP</button>
    </div>
  </main>
</template>

<script setup>
import { useCountryStore } from '@/stores/country'
import { useHeaderStore } from '@/stores/header'

const store = useCountryStore();
const storeHeader = useHeaderStore();
const route = useRoute()
const localePath = useLocalePath()
const runtimeConfig = useRuntimeConfig()
const baseUrl = runtimeConfig.public.baseURL

const isShowShellRecharge = computed(() => {
  const selectedContinentName = store.selectedContinentName;
  const selectedCountryCode = store.selectedCountryCode;
  return !(selectedContinentName === 'Europe' || selectedCountryCode === 'US')
})

useHead({
  meta: [
    {
      hid: 'og:url',
      name: 'og:url',
      content: `${baseUrl}${localePath(route.fullPath)}`
    }
  ]
})

storeHeader.setHeader({
  backBtnLink: localePath('index'),
  logoVisible: true,
  titleVisible: false,
  title: ''
})

const scrollTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
}
</script>

<style lang="scss" scoped>
main {
  position: relative;
  background-color: #fff;
  > section:first-child{margin-top: 0;}
  > section{margin-top: 104px;}
}

.btn_top{
  > button{
    display: block; position: fixed; right: 16px; bottom: 16px; z-index: 1;
    width: 40px; height: 20px; background-color: RGBA(0,0,0,0.5); border: 0; font-size: 11px; line-height: 20px; text-align: right; padding: 0 3px 0 13px; color: #fff;
    &::before{
      content: ''; display: block; position: absolute; width: 10px; height: 10px; top:5px; left: 3px;
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.75 6.875L5 3.125L1.25 6.875' stroke='white' stroke-width='2'/%3E%3C/svg%3E");
    }
  }
}

.main_tit{
  height: 100vh;
  height: var(--app-height);
  .img_tit{
    position: relative;transition: all 0.2s;
    display: flex; align-items: center; height: 100%;overflow: hidden;
    > video {
      position: absolute;
      top:0;left:0;right:0;bottom:0;
      width:100%;
      height:100%;
      object-fit: cover;
    }
  }
  .sub{
    position: absolute;
    bottom: 48px;
    left: 16px;
    z-index: 1;
    h4{
      font-size: 48px; line-height: 72px; color: #fff;
    }
    .link_clamp{
      margin-top: 24px;
      &::after{
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 2L11 8L5 14' stroke='white' stroke-width='2'/%3E%3C/svg%3E");
      }
      > span{
        color:#fff;
      }
    }
  }
}

.main_owners{
  h4{
    font-size: 32px; line-height: 48px; margin: 0 16px;
  }
  > a{display: block;}
  .link_clamp{
    margin: 8px 16px 0 16px;
  }
  .view_world{
    margin-top:32px;
    > img{
      display: block;
    }
    > .img_pc{
      display: none;
    }
    > .img_mo{
      width: 100%;
    }
  }
}

.main_exp{
  h4{
    font-size: 32px; line-height: 48px; margin: 0 16px;
  }
  > a{display: block;}
  .link_clamp{
    margin: 8px 16px 0 16px;
  }
  .exp_list{
    > ul{
      display: flex; gap:16px;  align-items: flex-start;
      scroll-snap-type: x mandatory;overflow-x: auto; margin-top: 32px; padding: 0 16px;
      &::-webkit-scrollbar {
        background: transparent;
        height: 0px;
        display: none;
      }
      > li{
        scroll-snap-align: center;
        > a{
          display: block;
          > img{
            display: block; width: 268px;
          }
          > span{
            display: block; margin:16px 0 0 8px; font-size: 20px; line-height: 30px;
          }
        }
      }
    }
  }
}

.main_webtoon{
  h4{
    font-size: 32px; line-height: 48px; margin: 0 16px;
  }
  > a{display: block;}
  .link_clamp{
    margin: 8px 16px 0 16px;
  }
  .view_webtoon{
    margin-top:32px;
    > img{
      display: block; width: 100%;
    }
    > .img_pc{
      display: none;
    }
    > .img_mo{
      display: block;
    }
  }
}

.main_game{
  h4{
    font-size: 32px; line-height: 48px; margin: 0 16px;
  }
  > a{display: block;}
  .link_clamp{
    margin: 8px 16px 0 16px;
  }
  .view_game{
    margin-top:32px;
    > img{
      display: block; width: 100%;
    }
    > .img_pc{
      display: none;
    }
    > .img_mo{
      display: block;
    }
  }
}

.main_more{
  margin-bottom:107px;
  h4{
    font-size: 24px; line-height: 36px; padding:32px 16px 0;
  }
  > a{display: block;}
  .link_clamp{
    margin: 8px 16px;
  }
  .find_shell{
    margin: 0 16px 16px 16px; height: 240px; background-color: #bed2e8;
    background-image: url("/assets/images/main/bg_shell_02_mo.png"); background-size: contain; background-repeat: no-repeat; background-position: right bottom;
  }
  .more_info{
    position: relative; margin: 0 16px; height: 240px; background-color: #f1eee7;
    background-image: url("/assets/images/main/bg_more_mo.png"); background-size: contain; background-repeat: no-repeat; background-position: right bottom;
  }
}

.link_clamp{
  display:inline-block; position: relative; border: 0; text-align: left; padding:8px 20px 8px 0;
  &::after{
    content: ''; display: block; position: absolute; width: 16px; height: 16px; top:calc(50% - 8px); right: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 2L11 8L5 14' stroke='black' stroke-width='2'/%3E%3C/svg%3E");
  }
  > span{
    display:block; font-weight: 500; font-size: 16px; line-height: 24px; color:#000;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (min-width: 768px) {
  main {
    > section{
      max-width: 1152px; padding: 104px 16px 0 16px; margin: 0 auto;
      &.main_tit{max-width: none;}
    }
    > section:first-child{
      padding-top: 0;
    }
  }
  .btn_top{
    > button{
      right: 40px; bottom: 45px; width: 60px; height: 30px; font-size: 16px; line-height: 20px;  padding: 5px 5px 5px 23px;
      &::before{
        width: 14px; height: 14px; top:7px; left: 5px;
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.25 9.625L7 4.375L1.75 9.625' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
      }
    }
  }
  .main_tit{
    height: 824px;padding:0px;
    .sub{
      bottom: 100px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 1152px;
      padding: 0 16px;
    }
  }

  .main_owners{
    h4{
      margin: 0;
    }
    .link_clamp{
      margin: 8px 0 0 0;
    }
    .view_world{
      > img{
        display: block; width: 100%;
      }
      > .img_pc{
        display: block;
      }
      > .img_mo{
        display: none;
      }
    }
  }

  .main_exp{
    h4{
      margin: 0;
    }
    .exp_list{
      padding: 0;
      > ul{
        padding: 0;
      }
    }
  }

  .main_webtoon{
    h4{
      margin: 0;
    }
    .link_clamp{
      margin: 8px 0 0 0;
    }
    .view_webtoon{
      > img{
        display: block; width: 100%;
      }
      > .img_pc{
        display: block;
      }
      > .img_mo{
        display: none;
      }
    }
  }

  .main_game{
    h4{
      margin: 0;
    }
    .link_clamp{
      margin: 8px 0 0 0;
    }
    .view_game{
      > img{
        display: block; width: 100%;
      }
      > .img_pc{
        display: block;
      }
      > .img_mo{
        display: none;
      }
    }
  }

  .main_more{
    h4{
      margin: 0;
      padding: 72px 160px 0;
      > br{
        display: none;
      }
    }
    .link_clamp{
      margin: 8px 160px;
    }
    .find_shell{
      margin: 0 0 24px 0;
      background-image: url("/assets/images/main/bg_shell_02_pc.png"); background-size: cover;
    }
    .more_info{
      margin: 0;
      background-image: url("/assets/images/main/bg_more_pc.png"); background-size: cover;
    }
  }
}
</style>
